import Q from "q";
import _ from "underscore";
import get_menu_data from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_menu_data/1.0.0";
import {getSession, setSession} from "@/lib/deprecated/utils/caching";
import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
// 获取token
import get_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_token";

export default {
    data() {
        return {
            data_get_menu_data: {
                menu_list: [],
                all_menu_list: [],
            },
            activeNames: [],
            // btoc: "",
            // buyerId: "",
        };
    },
    methods: {
        init() {
            const __this = this;
            const route_name = __this.$route.name;

            Q.when()
                .then(function () {
                    return __this.__init_menu();
                })
                .then(function () {
                    const menu_list = __this.data_get_menu_data.menu_list;

                    for (let i = 0; i < menu_list.length; i++) {
                        const list = menu_list[i].sub_list;
                        for (let j = 0; j < list.length; j++) {
                            const item = list[j];
                            if (item.routeName === route_name) {
                                setSession("leftNavActive", JSON.stringify(item));
                            }
                        }
                    }

                    if (
                        route_name === "distributor-tour-back-tour-with-group-order-list"
                    ) {
                        __this.__init_menu_active({
                            route_name: route_name,
                        });
                        return;
                    }

                    __this.__init_menu_active({
                        route_name: route_name,
                    });
                })
                .catch(function (ex) {
                    console.error(ex);
                    console.trace();
                    if (ex) {
                        if (ex.stack) {
                            console.error(ex.stack);
                        }
                    }
                    return Q.reject(ex);
                });
        },

        __btn_nav_click(pEntity) {
            const __this = this;

            pEntity.active_status = true;
            __this.$router.push({name: pEntity.routeName});
            document.documentElement.scrollTop = 0;
        },

        __el_collapse_change_event_handler() {
        },

        __init_menu() {
            const __this = this;
            return Q.when()
                .then(function () {
                    return get_menu_data();
                })
                .then(function (data) {
                    console.log(data)
                    let menuList = [];
                    let oldMenuList = data.menu_list;
                    let menuListTit = ['国内机票订单', '国际机票订单', '对账管理', '余额记账', '授信记账', '个人中心', '接口对接配置', '轻松付'];
                    oldMenuList.forEach(value=>{
                        if (menuListTit.indexOf(value.resourceName) > -1) {
                            menuList.push(value)
                        }
                    });
                    data.menu_list = menuList;
                    __this.data_get_menu_data = data;
                    global.data_get_menu_data = data;
                })
                .catch(function (ex) {
                    console.error(ex);
                    console.trace();
                    if (ex) {
                        if (ex.stack) {
                            console.error(ex.stack);
                        }
                    }
                    return Q.reject(ex);
                });
        },

        async __init_menu_active({route_name, parent_name}) {
            const __this = this;
            const all_menu_list = __this.data_get_menu_data.all_menu_list;
            let query = null;
            query = await getRouteName(all_menu_list, route_name);
            !query.length || !query
                ? (query = await getRouteName(all_menu_list, parent_name))
                : "";

            _.each(all_menu_list, function (m, i) {
                m.active = false;
            });
            // const query = _.chain(all_menu_list)
            //   .find(function (m) {
            //     return m.routeName === route_name;
            //   })
            //   .value()
            //   ;
            if (!query.length) return;
            query[0].active = true;
            setSession("leftNavActive", query);
            this.getMenuList();

            function getRouteName(menuList, routerName) {
                return menuList.filter((item) => {
                    return item.routeName === routerName;
                });
            }
        },
        getMenuList() {
            let list = this.data_get_menu_data.menu_list;
            list.some((item, index) => {
                if (item.sub_list) {
                    return item.sub_list.some((sItem) => {
                        if (sItem.active) {
                            // this.activeNames.indexOf(index) === -1 ?
                            //   this.activeNames.push(index) : ''
                            this.activeNames = [index];
                            return true;
                        }
                    });
                }
            });
        },
        getSessionActive(menuList, query) {
            let value = query[0];
            menuList.map((item) => {
                item.routeName === value.routeName
                    ? (item.active = true)
                    : item.sub_list
                    ? this.getSessionActive(item.sub_list, query)
                    : "";
            });
            this.getMenuList();
        },

        to_b2c_admin(status) {
            const prefix = get_url_prefix({appKey: `frontend-b2c-admin`});
            const token = get_token();
            let url = "";
            if (status === 0) {
                url = `${prefix}/redirect?redirectType=001&token=${token}`;
            } else if (status === 1) {
                url = `${prefix}/redirect?redirectType=002&token=${token}`;
            }
            window.open(url, "_blank");
        },
    },
    watch: {
        $route(pNewValue, pOldValue) {
            const __this = this;

            __this.__init_menu_active({
                route_name: pNewValue.name,
                parent_name: pOldValue.name,
            });
        },
    },
    mounted() {
        this.init();
    },
};
